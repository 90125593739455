<template>
    <main class="h-100 w-100">
      <h4 class="mt-2">Task Processors</h4>

      <table class="table table-fixed table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Type</th>
            <th scope="col">Model Base</th>
            <th scope="col">Cost / Frame</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in sortedResources" :key="resource.id">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      TODO
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>
    </main>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: {  },
  components: {
  },
  data() {
      return {
        errorLoading: null,
        isEditingName: false,
        editModal: null,
        resources: [],
        selectedResourceId: null,
        saving: false,
        page: 0,
      }
  },
  async mounted() {
    
  },
  async destroyed() {
    
  },
  computed: {
    selectedResource() { return this.resources.find(x => x.id == this.selectedResourceId) },
    sortedResources() {
      return this.resources
    },
  },
  watch: {
  },
  methods: {
    
    async infiniteHandler($state) {
     
      if (0) {
        this.page += 1;
        this.resources.push(...data.resources);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  }
}
</script>

<style>

</style>